import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from './util/services/local-storage/local-storage.service';
import { Observable } from 'rxjs';

@Injectable()
export class Globals {

  constructor(private http: HttpClient, private localStorage: LocalStorageService) {
  }

  getHeader() {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      });
  }

  makeUrl(nombreServicio: string, search: any | undefined, pagination: any | undefined): string {
    let url: string = `${environment.API_ENDPOINT_ES}${nombreServicio}`;
    const params = [];

    for (const prop in search) {
      if (search[prop] !== undefined && search[prop] !== null && search[prop] !== '') {
        params.push(prop + '=' + search[prop]);
      }
    }

    for (const prop in pagination) {
      if (pagination[prop] !== undefined) {
        params.push(prop + '=' + pagination[prop]);
      }
    }

    if (params.length) {
      url += '?' + params.join('&');
    }

    return url;
  }

  getSystemVersion(): string { return environment.system_version.version; }

  loginMicrosoft() {
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Token: this.localStorage.getTokenFromStorage()
      }
    )
    const url = `${environment.API_ENDPOINT_ES}/loginPbi`;
    return this.http.get(url, { headers });
  }

  refreshTokenPBI(): Observable<any> {
    const pbi_access = JSON.parse(this.localStorage.getStoredData('pbi_access'));
    const jsonData = { refresh_token: pbi_access.refresh_token };
    const headers = this.getHeader();
    const url = `${environment.API_ENDPOINT_ES}/refreshPbi`;
    return this.http.post(url, jsonData, { headers });
  }

}
