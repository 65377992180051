import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.sass']
})
export class PageTitleComponent implements OnInit {

  @Input() heading: string;
  @Input() showIcon: boolean;
  @Input() icon: string;
  @Input() subheading: string;
  @Output() clicked?: EventEmitter<boolean> = new EventEmitter<boolean>();
  // showButton permite la visualización del botón
  @Input() showButton: boolean;
  // permiso asociado a la creación: es un string
  @Input() permissionCreate: string;


  constructor(private permissionService: NgxPermissionsService) {}

  ngOnInit(): void {
    this.verificarPermiso();
  }

  verificarPermiso() {
    if (this.permissionCreate) {
      this.permissionService.hasPermission(this.permissionCreate).then((result: boolean) => {
        result ? this.showButton = true : this.showButton = false;
      });
    }
  }


}
