import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoginService } from '../services/login.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from '../../util/services/error-service/error.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ParametrosLoginResponse } from '../interfaces/login-responses';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-recuperar-contrasenha',
  templateUrl: './recuperar-contrasenha.component.html',
  styleUrls: ['./recuperar-contrasenha.component.sass']
})
export class RecuperarContrasenhaComponent implements OnInit {

  @Input() recoveryToken: string
  parametrosIniciales: any;
  newAccessToken: any;
  recuperarForm: FormGroup;
  @Output() hide: EventEmitter<boolean> = new EventEmitter<boolean>();
  cargando: boolean;

  constructor(
    private loginService: LoginService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private errorService: ErrorService,
    private router: Router,
    private fB: FormBuilder) {
  }

  ngOnInit(): void {
    this.getParametrosLogin();
    this.route.queryParams.pipe(
      filter((params: any) => params.t))
      .subscribe(params => {
        this.recoveryToken = params.t;
        if (this.recoveryToken) {
          console.log("RT: ", true);
        }
      }, error => {
        console.error(error);
      });
  }

  getParametrosLogin(): void {
    this.cargando = true;
    this.loginService.getParametrosLogin()
      .subscribe((response: ParametrosLoginResponse) => {
        if (response.success) {
          this.parametrosIniciales = response.data;
          this.loadForm();
        }
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.cargando = false;
      });
  }

  verifyToken() {
    this.loginService.verificarTokenChangePassword(this.recoveryToken).subscribe((response: any) => {
      if (response.success) {
        this.newAccessToken = response.data;
      } else {
        this.toastr.error(response.errors[0], 'Error de Validación');
        this.hide.emit(true);
      }
    }, err => {
      this.errorService.handleError(err);
      this.hide.emit(true);
    }, () => {
      this.recuperarForm.get('token').setValue(this.newAccessToken.token);
    });
  }

  loadForm() {
    this.recuperarForm = this.fB.group({
      email: [undefined, [Validators.email, Validators.required]],
      token: [undefined, [Validators.required]],
      password: [undefined, [Validators.minLength(+this.parametrosIniciales.MINPASSWORD.MINPASSWORD), Validators.maxLength(+this.parametrosIniciales.MAXPASSWORD.MAXPASSWORD), Validators.required]],
      password_confirmation: [undefined, [Validators.minLength(+this.parametrosIniciales.MINPASSWORD.MINPASSWORD), Validators.maxLength(+this.parametrosIniciales.MAXPASSWORD.MAXPASSWORD), Validators.required]]
    });
    this.verifyToken();
  }

  actualizarPassword() {
    this.cargando = true;
    if (this.recuperarForm.controls.password.value !== this.recuperarForm.controls.password_confirmation.value) {
      this.toastr.error('Las contraseñas no coinciden', 'Error');
    } else {
      this.loginService.recuperarPassword(this.recuperarForm.value).subscribe((response) => {
        this.toastr.success('La contraseña ha sido actualizada correctamente', 'Actualizar contraseña');
      },
        error => {
          this.errorService.handleError(error);
        }, () => {
          this.router.navigate(['login'])
        });
    }
  }


  onEyePasswordClick(step: string): void {
    let typePassword: string;
    switch (step) {
      case 'first':
        typePassword = 'password'
        break;
      case 'second':
        typePassword = 'password2'
        break;
      default:
        break;
    }
    let input = (<HTMLInputElement>document.getElementById(typePassword));
    let type = input.type;
    type === 'password' ? input.type = 'text' : input.type = 'password';
  }


}
