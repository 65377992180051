<div class="example-container">
  <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="shadow mat-elevation-z10 dark:bg-gray-400">
    <!--/*style="background-color: #616161"*/-->
    <div fxLayout="row" fxLayoutAlign="center center">
      <button mat-icon-button (click)="snav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <span fxLayout="row" fxLayoutAlign="center center" style="height: 80px" class="example-app-name">
      </span>
    </div>
    <button mat-icon-button title="Salir">
      <img height="80" width="100" src="/assets/images/small_icon_familiar.png" alt="Logo Banco Familiar">
    </button>

    <div style="width: 100px" *ngIf="localStorageService.authenticated">
      <div fxLayout="row" fxLayoutGap="2%">
        <button style="width: 40px" fxLayout="row" fxLayoutAlign="start start" mat-icon-button
          (click)="switchMode(isDark)">
          <mat-icon style="font-size: 40px">nightlight_round</mat-icon>
          <!--widgets-->
        </button>
        <button style="width: 40px" fxLayout="row" fxLayoutAlign="start start" mat-icon-button
          [matMenuTriggerFor]="menu">
          <img *ngIf="usuario.userData.foto_perfil !== null" class="inline-block h-10 w-10 rounded-full"
            [src]="imageToShowDialog" alt="Foto de perfil" />
          <mat-icon *ngIf="usuario.userData.foto_perfil === null" style="font-size: 40px">account_circle</mat-icon>
          <!--widgets-->
        </button>
      </div>
      <mat-menu #menu="matMenu">
        <div fxLayout="row wrap" fxLayoutAlign="center center">

          <button style="width: 40px" fxLayout="row" fxLayoutAlign="start start" mat-icon-button>
            <img *ngIf="usuario.userData.foto_perfil !== null" class="inline-block h-10 w-10 rounded-full"
              [src]="imageToShowDialog" alt="Foto de perfil" />
            <mat-icon *ngIf="usuario.userData.foto_perfil === null" style="font-size: 40px">account_circle</mat-icon>
          </button>
          <div class="mt-3" fxFlex="100" fxLayout="column" fxLayoutAlign="center center" style="height: 80px">
            <div class="text-black text-lg font-bold font-sans">{{usuario?.userData.nombre}}
              {{usuario?.userData.apellido}}</div>
            <div class="font-semibold text-sm inline-block py-1 px-3 m-2 rounded-full bg-blue-700text-white">
              {{usuario?.userName}}
            </div>
          </div>
        </div>

        <button mat-menu-item (click)="openSettings()">
          <mat-icon color="primary">verified_user
            <span style="margin-left: 6px; border-right: 1px solid #D2D2D2"></span>
          </mat-icon>
          <span>Cuenta</span>
        </button>

        <button mat-menu-item (click)="logout()">
          <mat-icon color="primary">logout
            <span style="margin-left: 6px; border-right: 1px solid #D2D2D2"></span>
          </mat-icon>
          <span>Salir</span>
        </button>
        <!--        <button mat-menu-item (click)="openConfiguracionModal()">-->
        <!--          <mat-icon color="primary">settings_suggest-->
        <!--            <span style="margin-left: 6px; border-right: 1px solid #D2D2D2"></span>-->
        <!--          </mat-icon>-->
        <!--          <span>Configuración</span>-->
        <!--        </button>-->
        <!--        <button mat-menu-item (click)="logout()"> &lt;!&ndash; [routerLink]="'/login'" &ndash;&gt;-->
        <!--          <mat-icon color="primary">input-->
        <!--            <span style="margin-left: 6px; border-right: 1px solid #D2D2D2"></span>-->
        <!--          </mat-icon>-->
        <!--          <span>Salir</span>-->
        <!--        </button>-->
      </mat-menu>
    </div>
  </mat-toolbar>

  <mat-drawer-container class="example-sidenav-container" [hasBackdrop]="this.isSmall ? true : false">
    <!--<mat-drawer #snav [ngClass.lt-md]="'mat-sidenav-sm'" [ngClass.gt-md]="'mat-sidenav-bg'" mode="side">-->
    <mat-drawer #snav [mode]="this.isSmall ? 'over' : 'side'" [opened]="isMenuOpen" [class.menu-open]="isMenuOpen"
      class="shadow1 mat-elevation-z10 bg-white dark:bg-gray-700 w-80">
      <div fxLayout="column" fxLayoutAlign="space-between" style="height: 100%"
        *ngIf="localStorageService.authenticated">
        <div fxFlex="90">
          <mat-accordion multi *ngxPermissionsOnly="['ADM/mant_personas/list206', 
          'ADM/mant_usuarios/listListarUsuarios',
          'ADM/mant_roles/list255',
          'ADM/mant_parametros/list231',
          'ADM/mant_modulos/list239',
          'ADM/mant_auditorias_reportes/list277'
          ]">
            <mat-expansion-panel [expanded]="true" class="mat-elevation-z0 dark:bg-gray-700">
              <mat-expansion-panel-header class="dark:bg-gray-700 dark:hover:bg-gray-500"
                (click)="$event.stopPropagation()">
                <mat-panel-title class="dark:text-white">
                  Administración
                </mat-panel-title>
                <mat-divider></mat-divider>
              </mat-expansion-panel-header>

              <mat-nav-list class="dark:bg-gray-700 w-full">
                <mat-list-item *ngxPermissionsOnly="'ADM/mant_personas/list206'" class="dark:text-white w-full"
                  [routerLink]="['/administracion/personas']" routerLinkActive="bg-gray-100">
                  <mat-icon mat-list-icon>people</mat-icon>
                  <a matLine>Personas</a>
                  <mat-divider></mat-divider>
                </mat-list-item>
                <mat-list-item *ngxPermissionsOnly="'ADM/mant_usuarios/list200'" class="dark:text-white"
                  [routerLink]="['/administracion/usuarios']" routerLinkActive="bg-gray-100">
                  <mat-icon mat-list-icon>admin_panel_settings</mat-icon>
                  <a matLine>Usuarios</a>
                  <mat-divider></mat-divider>

                </mat-list-item>
                <mat-list-item *ngxPermissionsOnly="'ADM/mant_roles/list255'" class="dark:text-white"
                  [routerLink]="['/administracion/roles']" routerLinkActive="bg-gray-100">
                  <mat-icon mat-list-icon>privacy_tip</mat-icon>
                  <a matLine>Roles</a>
                  <mat-divider></mat-divider>

                </mat-list-item>
                <mat-list-item *ngxPermissionsOnly="'ADM/mant_modulos/list239'" class="dark:text-white"
                  [routerLink]="['/administracion/auditorias']" routerLinkActive="bg-gray-100">
                  <mat-icon mat-list-icon>gavel</mat-icon>
                  <a matLine>Auditorías</a>
                  <mat-divider></mat-divider>

                </mat-list-item>
                <mat-list-item *ngxPermissionsOnly="'ADM/mant_parametros/list231'" class="dark:text-white"
                  [routerLink]="['/administracion/parametros-sistema']" routerLinkActive="bg-gray-100">
                  <mat-icon mat-list-icon>grid_view</mat-icon>
                  <a matLine>Parámetros del Sistema</a>
                  <mat-divider></mat-divider>
                </mat-list-item>
                <mat-list-item *ngxPermissionsOnly="'ADM/mant_auditorias_reportes/list277'" class="dark:text-white"
                  [routerLink]="['/administracion/uso-reportes']" routerLinkActive="bg-gray-100">
                  <mat-icon mat-list-icon>info</mat-icon>
                  <mat-divider></mat-divider>
                  <a matLine>Uso de Reportes</a>
                </mat-list-item>

              </mat-nav-list>

            </mat-expansion-panel>
          </mat-accordion>


          <mat-nav-list class="bg-white dark:bg-gray-700">
            <mat-list-item class="text-black dark:text-white" [routerLink]="['/parametricos']"
              *ngxPermissionsOnly="'PAR/mant_dir_tipo/list11'" routerLinkActive="bg-gray-100">
              <mat-icon>poll</mat-icon>
              <a matLine>
                Paramétricos
              </a>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item class="text-black dark:text-white" [routerLink]="['/workspaces']"
              routerLinkActive="bg-gray-100">
              <mat-icon>dynamic_form</mat-icon>
              <a matLine>
                Workspaces
              </a>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item *ngxPermissionsOnly="'Arch/mant_archivo/list502'" class="text-black dark:text-white"
              [routerLink]="['/archivos']" routerLinkActive="bg-gray-100">
              <mat-icon>folder</mat-icon>
              <a matLine>
                Archivos
              </a>
            </mat-list-item>
            <mat-divider></mat-divider>

          </mat-nav-list>

        </div>
        <!--<mat-nav-list>-->
        <div style="margin: 5px">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <span class="dark:text-white">Versión: {{getSystemVersion()}}</span>
          </div>
        </div>
        <!--</mat-nav-list>-->
      </div>

    </mat-drawer>

    <mat-drawer-content class="bg-white dark:bg-gray-900">
      <!--#d2d2d2-->

      <!--<button mat-button color="primary" (click)="active()">
        Active
      </button>-->
      <div class="main-container mb-10">
        <router-outlet></router-outlet>
      </div>
      <!--      <app-footer></app-footer>-->
    </mat-drawer-content>
  </mat-drawer-container>
</div>


<div *ngIf="isLoading$ | async" class="loading-container">
  <mat-progress-spinner class="spinner" color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>