import { Component, OnInit } from '@angular/core';
import { LoginService } from './services/login.service';
import { ErrorService } from '../util/services/error-service/error.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ParametrosLogin, ParametrosLoginResponse } from './interfaces/login-responses';
import { ToastrService } from 'ngx-toastr';
import { User } from '../util/interfaces/user/user';
import { AuthService } from '../auth/services/auth.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { ForzarCambioContrasenhaComponent } from './forzar-cambio-contrasenha/forzar-cambio-contrasenha.component';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Globals } from '../Globals';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from '../util/services/local-storage/local-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  parametrosLogin: ParametrosLogin;
  show: string = 'inicio';
  isLoading$: Observable<boolean>;
  recoveryToken: string;

  constructor(private loginService: LoginService,
    private errorService: ErrorService, private authService: AuthService,
    private fB: FormBuilder, private toastr: ToastrService,
    private matDialog: MatDialog, private route: ActivatedRoute,
    private globals: Globals, private localStorage: LocalStorageService,
    private store: Store<{ count: boolean }>) {
  }

  ngOnInit(): void {
    this.isLoading$ = this.store.select('count');
    this.getParametrosLogin();
    this.route.queryParams.pipe(
      filter(params => params.t))
      .subscribe(params => {
        this.recoveryToken = params.t;
        if (this.recoveryToken) {
          this.show = 'recover';
        }
      }, error => {
        console.error(error);
      });
  }

  getParametrosLogin(): void {
    this.loginService.getParametrosLogin()
      .subscribe((response: ParametrosLoginResponse) => {
        if (response.success) {
          this.parametrosLogin = response.data;
        }
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.makeForm();
      });
  }

  makeForm() {
    this.loginForm = this.fB.group({
      username: [undefined, [Validators.required]],
      password: [undefined, Validators.required]
    });

    if (this.parametrosLogin.LOGINTYPE.LOGINTYPE === 'email') {
      this.loginForm.get('username').setValidators(Validators.email);
      this.loginForm.get('username').updateValueAndValidity();
    }
  }

  iniciarSesion() {
    const username = this.loginForm.controls.username.value;
    const password = this.loginForm.controls.password.value;
    const application = 'app1';

    if (username !== '' && password !== '') {
      this.loginService.loginUsuario(username, password, application)
        .subscribe((data: any) => {
          // const respuesta = data as LoginResponse;
          const respuesta = data;

          if (respuesta.success) {
            if (!respuesta.data.change_password && respuesta.data.dias_expiracion !== 0) {
              const usuario = new User(username, password, respuesta.data);
              this.authService.login(usuario);
            } else {
              const usuario = new User(username, password, respuesta.data);
              this.matDialog.open(ForzarCambioContrasenhaComponent, {
                disableClose: true,
                data: usuario
              })
                .afterClosed().subscribe(result => {
                  if (result) {
                    this.authService.login(usuario);
                    this.globals.loginMicrosoft().subscribe(
                      (data: any) => {
                        this.localStorage.storeData('pbi_access', JSON.stringify(data.data));
                      },
                      (error: HttpErrorResponse) => {
                        const respuesta = error;
                        if (respuesta.error.success === false) {
                          const mensaje = respuesta.error.errors.join('<br>');
                          this.toastr.error(mensaje, 'Error: ' + error.status);
                        } else {
                          this.toastr.error('Ocurrio un error', 'Error: ' + error.status);
                        }
                      }
                    )
                  }
                });
            }
          } else {
            console.error('No se pudo obtener datos de Login: ' + JSON.stringify(respuesta));
          }

        },
          error => {
            const respuesta = error;
            if (respuesta.error.success === false) {
              const mensaje = respuesta.error.errors.join('<br>');
              this.toastr.error(mensaje, 'Error: ' + error.status);
            } else {
              this.toastr.error('Ocurrio un error', 'Error: ' + error.status);
            }
          },
          () => {
            this.globals.loginMicrosoft().subscribe(
              (data: any) => {
                this.localStorage.storeData('pbi_access', JSON.stringify(data.data));
              },
              (error: HttpErrorResponse) => {
                const respuesta = error;
                if (respuesta.error.success === false) {
                  const mensaje = respuesta.error.errors.join('<br>');
                  this.toastr.error(mensaje, 'Error: ' + error.status);
                } else {
                  this.toastr.error('Ocurrio un error', 'Error: ' + error.status);
                }
              }
            )
          }
        );
    }
  }

  changeVisual(show: string): void {
    this.show = show;
  }

  onEyePasswordClick(): void {
    let input = (<HTMLInputElement>document.getElementById('password'));
    let type = input.type;
    type === 'password' ? input.type = 'text' : input.type = 'password';
  }

  toUppercase(event: Event): void {
    const input = (event.target as HTMLInputElement).value;
    this.loginForm.controls['username'].setValue(input.toUpperCase());
  }

}
