<mat-card class="mb-5 mt-4 border-1 border-gray-300 shadow-2xl dark:bg-gray-900">
  <p class="text-3xl text-gray-600 hover:text-blue-500 dark:text-white">
    <mat-icon *ngIf="showIcon">{{icon}}</mat-icon>
    {{heading}}
  </p>
  <mat-card-subtitle *ngIf="subheading">{{subheading}}</mat-card-subtitle>
  <div class="flex items-start mt-5" *ngIf="showButton">
    <mat-divider class="mb-3"></mat-divider>
    <button class="py-4
    px-5
    h-10
    mt-5
    w-auto
    inline-flex
    items-center
    justify-center
    text-center text-black text-base
    bg-yellow-400
    hover:bg-opacity-90
    font-normal
    rounded-full" (click)="clicked.emit(true)">
      <mat-icon>add</mat-icon>
      Nuevo registro
    </button>
  </div>
</mat-card>