<div class="bg-gradient-to-r from-blue-900 via-blue-500 to-blue-900 p-9">
  <mat-card fxFlex class="overflow-hidden">
    <mat-card-content style="min-height: 92vh;" fxLayout="row" fxLayout.lt-md="column">
      <div fxFlex="50%">
        <ng-container *ngIf="cargando === false">
          <div fxLayout="column" fxLayoutAlign="center center" class="min-h-full" *ngIf="parametrosIniciales">
            <!-- LOGO EN RESPONSIVE -->
            <div fxFlex fxLayout="column" fxLayoutAlign="center center" fxShow.lt-md fxHide.gt-sm>
              <div fxFlex fxFlexOffset="15">
                <img src="/assets/images/familiar_logo.png" alt="Logo Banco Familiar">
              </div>
            </div>
            <form [formGroup]="recuperarForm" *ngIf="recuperarForm">
              <div fxLayout="column" fxLayoutAlign="space-around space-between" fxLayoutGap="8%">
                <div class="text-center">
                  <h2 class="uppercase text-2xl font-sans font-extrabold">Recuperar contraseña</h2>
                </div>
                <mat-form-field appearance="outline" fxFlex>
                  <mat-label>Dirección de email</mat-label>
                  <input matInput type="email" autocomplete="email" required formControlName="email">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex>
                  <mat-label>Contraseña nueva</mat-label>
                  <input #first id="password" matInput type="password" required formControlName="password"
                    autocomplete="new-password">
                  <mat-icon *ngIf="first.type === 'password'" style="cursor: pointer;" matSuffix
                    (click)="onEyePasswordClick('first')">
                    visibility
                  </mat-icon>
                  <mat-icon *ngIf="first.type === 'text'" style="cursor: pointer;" matSuffix
                    (click)="onEyePasswordClick('first')">
                    visibility_off
                  </mat-icon>
                  <mat-hint>
                    La contraseña debe tener una longitud mínima de {{parametrosIniciales.MINPASSWORD.MINPASSWORD}}
                    caracteres
                    y un maximo de {{parametrosIniciales.MAXPASSWORD.MAXPASSWORD}} caracteres.
                  </mat-hint>
                  <mat-error
                    *ngIf="recuperarForm.get('password').hasError('minlength') && recuperarForm.get('password').touched">
                    Debe introducir al menos {{parametrosIniciales.MINPASSWORD.MINPASSWORD}} caracteres.
                  </mat-error>
                  <mat-error
                    *ngIf="recuperarForm.get('password').hasError('maxlength') && recuperarForm.get('password').touched">
                    Debe introducir sólo hasta {{parametrosIniciales.MAXPASSWORD.MAXPASSWORD}} caracteres.
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex class="mt-3">
                  <mat-label>Repetir contraseña nueva</mat-label>
                  <input #second matInput id="password2" type="password" autocomplete="new-password"
                    formControlName="password_confirmation" required>
                  <mat-icon *ngIf="second.type === 'password'" style="cursor: pointer;" matSuffix
                    (click)="onEyePasswordClick('second')">
                    visibility
                  </mat-icon>
                  <mat-icon *ngIf="second.type === 'text'" style="cursor: pointer;" matSuffix
                    (click)="onEyePasswordClick('second')">
                    visibility_off
                  </mat-icon>
                </mat-form-field>
                <div fxLayout="row" fxLayoutAlign="center">
                  <hr fxFlex="60%">
                </div>
                <div fxLayout="row" fxLayoutGap="2%">
                  {{recuperarForm.errors}}
                  <button fxFlex type="submit" mat-raised-button color="primary" (click)="actualizarPassword()"
                    [disabled]="!recuperarForm.valid">Actualizar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </ng-container>
        <ng-container *ngIf="cargando">
          <div fxLayout="column" fxLayoutAlign="center center" class="min-h-full">
            <mat-progress-spinner class="spinner" color="primary" mode="indeterminate">
            </mat-progress-spinner>
          </div>
        </ng-container>
      </div>
      <div fxFlex="50%" fxHide.lt-md class="bg-white">
        <div fxLayout="column">
          <div fxFlex fxLayout="column" fxLayoutAlign="center center">
            <div fxFlex fxFlexOffset="5">
              <img src="/assets/images/familiar_logo.png" alt="Logo Banco Familiar">
            </div>
            <div fxFlex class="mb-5">
            </div>
          </div>
          <div fxFlex="calc(28em + 20px)" fxFlexOffset="20" class="lg:bg-blue-900" fxLayout="column"
            fxLayoutAlign="center center" fxHide.lt-md>
            <img src="assets/images/login/Frame.png" alt="" style="position: relative; bottom: 180px" fxHide.lt-md>
            <div fxLayout="row" style="position: relative; top: -200px">
              <p class="uppercase text-2xl text-white font-mono font-bold">Portal de reportes</p>
            </div>
            <div fxLayout="row">
              <!-- <img src="/assets/es-powered-by.png" alt="Powered by: Enterprise Solutions" class="w-64 h-12 mr-2"
                style="position: relative; top: -65px" fxHide.lt-md> -->
              <a href="http://www.enterprisesolutions.com.py/" target="_blank">
                <img src="/assets/images/enterprise_white_logo.png" alt="Enterprise Solutions" class="w-56 h-16"
                  style="position: relative; top: -55px" fxHide.lt-md>
              </a>
              <img src="/assets/images/logo_bix.png" alt="Logo BIX" class="w-20 h-14 mt-4 mb-2"
                style="position: relative; top: -65px" fxHide.lt-md>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>