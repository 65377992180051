import { Injectable } from '@angular/core';
import { ErrorResponse } from './interfaces/error-response';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private toastr: ToastrService) { }

  handleError(error: any) {
    if (error.status === 409 || error.status === 422 || error.status === 403 || error.status === 500) {
      const mensaje: ErrorResponse = error.error;
      if (mensaje.errors && mensaje.errors.length >= 0) {
        mensaje.errors.forEach(mensajeError => {
          this.toastr.error(mensajeError, 'Atención');
        });
      } else {
        if (mensaje.message) {
          this.toastr.error(mensaje.message, 'Atención');
        } else {
          this.toastr.error('Respuesta de servidor no esperada. Contacte con el administrador', 'Atención',
          );
        }
      }
    } else if (error.status === 400) {
      //this.toastr.info('Se produjo un error. Revise el mensaje de alerta', 'Atención');
    }
    else {
      this.toastr.error('Se produjo un error en el sistema. Revise los mensajes de alerta o contacte con el administrador', 'Atención');
    }
  }

}