import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { BaseLayoutComponent } from './base-layout/base-layout.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { HomeComponent } from './home/home.component';
import { RecuperarContrasenhaComponent } from './login/recuperar-contrasenha/recuperar-contrasenha.component';

const routes: Routes = [
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'recuperar', component: RecuperarContrasenhaComponent,
  },
  {
    path: '', component: BaseLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'prefix'
      },
      {
        path: 'administracion',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./administracion/administracion.module').then(m => m.AdministracionModule)
      },
      {
        path: 'parametricos',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./parametricos/parametricos.module').then(m => m.ParametricosModule)
      },
      {
        path: 'workspaces',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./reportes/reportes.module').then(m => m.ReportesModule)
      },
      {
        path: 'archivos',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./archivos/archivos.module').then(m => m.ArchivosModule)
      },
      {
        path: 'home',
        component: HomeComponent
      },
    ]
    , canActivate: [AuthGuardService],
  },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      useHash: true
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
